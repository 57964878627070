import logo from './logo2.svg';
import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <div className='main-p'>
          <p>
            I am <span style={{ color: 'red' }}> awais! </span>
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
